<template>
  <v-sheet img="" min-height="100vh">
    <v-img src="@/assets/web/marmol.jpg" height="100%">
      <v-form @submit.prevent="onSubmit" lazy-validation v-model="valid" ref="form">
        <v-container class="mt-16">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="'*' + text[lang].name" filled v-model="nombre" :rules="nameRules" required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field type="tel" :label="'*' + text[lang].phone" filled v-model="telefono" required minlength="10" maxlength="10" :rules="telRules">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field type="email" :label="'*' + text[lang].email" filled v-model="email" :rules="emailRules" required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field type="email" :label="'*' + text[lang].confirmEmail" filled v-model="emailRepeat" :rules="emailRules" required>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea filled :label="text[lang].message" v-model="mensaje">
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <vue-recaptcha ref="captcha" :sitekey="recaptcha.sitekey" @verify="onCaptchaVerified"></vue-recaptcha>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn tile type="submit" :disabled="enviando">
                {{ text[lang].btn }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="auto">
              <v-btn text href="https://www.instagram.com/hospitiummx/" style="text-transform: none;"> <v-icon >mdi-instagram</v-icon> @hospitiummx</v-btn>
              <v-btn text href="https://www.instagram.com/crconsultingmx/" style="text-transform: none;"> <v-icon >mdi-instagram</v-icon> @crconsultingm</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-img>
  </v-sheet>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import VueRecaptcha from 'vue-recaptcha';

let uri;
if (process.env.NODE_ENV === 'production') {
  uri = `//${document.domain}/backoffice`;
} else {
  uri = '//localhost:8090';
}

const sitekey = process.env.NODE_ENV === 'production' ? '6Le_quoZAAAAAC-v4Qr1z0D1SL7HhMTYtY587zuI' : '6LcgrOoZAAAAAGOzubnzUwNZpWhcn94YUAljqun8';

export default {
  name: 'contacto',
  components: {
    VueRecaptcha
  },
  data() {
    return {
      nombre: null,
      telefono: null,
      email: null,
      emailRepeat: null,
      mensaje: null,
      valid: true,
      nameRules: [
        v => !!v || this.text[this.lang].messageName
      ],
      emailRules: [
        v => !!v || this.text[this.lang].messageEmail1,
        v => /.+@.+\..+/.test(v) || this.text[this.lang].messageEmail2,
      ],
      telRules: [
        v => !!v || this.text[this.lang].messageTel1,
        v => Boolean(v) && v.length === 10 || this.text[this.lang].messageTel2,
        v => !Number.isNaN(+v) || this.text[this.lang].messageTel3,
      ],
      enviando: false,
      robot: false,
      recaptcha: {
        sitekey: sitekey
      },
      text: {
        es: {
          name: 'Nombre',
          phone: 'Teléfono',
          email: 'Email',
          confirmEmail: 'Confirmar Email',
          message: 'Mensaje',
          btn: 'Enviar',
          messageName: 'El nombre es requerido',
          messageTel1: 'El teléfono es requerido',
          messageTel2: 'La longitud del teléfono debe ser de 10 dígitos',
          messageTel3: 'El teléfono debe tener solo digitos',
          messageEmail1: 'El E-mail es requerido',
          messageEmail2: 'El E-mail debe ser valido',
        },
        en: {
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
          confirmEmail: 'Confirm Email',
          message: 'Message',
          btn: 'Send',
          messageName: 'Name required',
          messageTel1: 'Phone required',
          messageTel2: 'phone length not is 10 digit',
          messageTel3: 'phone is invalid',
          messageEmail1: 'E-mail required',
          messageEmail2: 'e-mail not is valid',
        }
      }
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang;
    }
  },
  methods: {
    onSubmit() {
      if (!this.robot) {
        Swal.fire(this.lang === 'es' ? 'Por favor confirma que no eres un robot' : 'Please confirm not is a robot', '', 'info');
        return;
      }

      let validate = this.$refs.form.validate();
      if (!validate) return;

      let data = {
        nombre: this.nombre,
        telefono: this.telefono,
        email: this.email,
        emailRepeat: this.emailRepeat,
        mensaje: this.mensaje,
        token: this.robot,
      };

      if (this.email !== this.emailRepeat) {
        Swal.fire('Error', this.lang === 'es' ? 'Los correos son diferentes' : 'email are differents', 'warning');
        return;
      }

      Swal.fire({
        title: this.lang === 'es' ? '¿Seguro que deseas enviar tu información?' : 'Sure send info?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'es' ? 'Si, enviar' : 'Yes, send',
        cancelButtonText: 'cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviando = true;
          axios.post(`${uri}/index.php`, data)
          .then(response => {
            if ('success' in response.data) {
              Swal.fire('', response.data.message, 'success');
              this.reset();
            } else {
              Swal.fire('Error', response.data.message, 'error');
            }
          })
          .catch(error => {
            Swal.fire('Error', 'Error', 'error');
            console.log(error);
          })
          .finally(() => {
            this.enviando = false;
            this.$refs.captcha.reset();
          });
        }
      })

    },
    reset() {
      // this.nombre = null;
      // this.telefono = null;
      // this.email = null;
      // this.emailRepeat = null;
      // this.mensaje = null;
      this.$refs.form.reset();
    },
    onCaptchaVerified(response) {
      if (response) this.robot = response;
    }
  }

}
</script>

